@import '_colors';

::-webkit-scrollbar {
    display: none;
}
.crude-main {
    background-color: $white;
    color: $black;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-y: scroll;

   
    .menu-item {
        margin: 0 0.5rem;
    }

    .crude-tabs-select {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

   
}

.report-style-class {
    height: 860px;
    width: 100%;
    margin: auto;
}

.dash-card {
    &.MuiPaper-root {
        background: transparent;
    }
    &.MuiCard-root {
        height: 130px;
        width: 30%;
    }
}

.crude-prices-chart-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.crude-prices-chart {
   width: 50%;
}


