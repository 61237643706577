$green1: #9cc400;
$green2: #00b050;
$green3: #338100;
$green4: #00632e;
$aramco_blue: #55a7d2;
$dark_blue: #33478e;
$dark_gray: #3d3933;
$gray: #747065;
$light_gray: #ede9dc;
$white:#ffffff;
$teal: #58b09d;
$teal_light: #92c9b9;
$teal_lighter: #cadfd2;
$purple: #6d3170;
$purple_light: #a57297;
$purple_lighter: #d2bcc5;
$yellow: #f6cf35;
$yellow_light: #f8df6a;
$yellow_lighter: #fbedab;
$red_darker: #b71c1c;
$red_dark: #d32f2f;
$red: #e5633f;
$red_light: #e99771;
$red_lighter: #f5c1b2;
$green: #c5e1a5;
$light_green: #D9E8CB;
$lighter_green: #EDF4E7;
$silver: #e9ecef;
$black: #000000;
$grey: grey;
$blue: #0000EE;
$twitter_blue: #00acee;
$blue_gray: #3D4647;
$blue_gray_darker: #394243;

.blue_gray {color: $blue_gray; }
.green1 { color: $green1; }
.green2 { color: $green2; }
.green3 { color: $green3; }
.green4 { color: $green4; }
.aramco_blue { color: $aramco_blue; }
.dark_blue { color: $dark_blue; }
.dark_gray { color: $dark_gray; }
.gray { color: $gray; }
.light_gray { color: $light_gray; }
.white { color: $white !important; }
.teal { color: $teal; }
.teal_light { color: $teal_light !important; }
.teal_lighter { color: $teal_lighter !important; }
.yellow { color: $yellow; }
.yellow_light { color: $yellow_light; }
.yellow_lighter { color: $yellow_lighter; }
.red_darker { color: $red_darker; }
.red_dark { color: $red_dark; }
.red { color: $red; }
.red_light { color: $red_light; }
.red_lighter { color: $red_lighter; }
.green { color: $green; }
.light_green { color: $light_green; }
.lighter_green { color: $lighter_green; }
.silver { color: $silver; }
.black { color: $black; }
.grey { color: $grey; }
.blue { color: $blue; }
.twitter_blue { color: $twitter_blue; }
.purple { color: $purple; }
.purple_light { color: $purple_light; }
.purple_lighter { color: $purple_lighter; }

.bg-blue_gray { background-color: $blue_gray; } 
.bg-blue_gray_darker { background-color: $blue_gray_darker; }
.bg-green1 { background-color: $green1 !important; }
.bg-green2 { background-color: $green2 !important; }
.bg-green3 { background-color: $green3; }
.bg-green4 { background-color: $green4; }
.bg-aramco_blue { background-color: $aramco_blue !important; }
.bg-dark_blue { background-color: $dark_blue !important; }
.bg-dark_gray { background-color: $dark_gray; }
.bg-gray { background-color: $gray; }
.bg-light_gray { background-color: $light_gray; }
.bg-white { background-color: $white; }
.bg-teal { background-color: $teal; }
.bg-teal_light { background-color: $teal_light !important; }
.bg-teal_lighter { background-color: $teal_lighter !important; }
.bg-yellow { background-color: $yellow; }
.bg-yellow_light { background-color: $yellow_light; }
.bg-yellow_lighter { background-color: $yellow_lighter; }
.bg-red { background-color: $red; }
.bg-red_light { background-color: $red_light; }
.bg-red_lighter { background-color: $red_lighter; }
.bg-green { background-color: $green; }
.bg-light_green { background-color: $light_green; }
.bg-lighter_green { background-color: $lighter_green; }
.bg-silver { background-color: $silver; }
.bg-black { background-color: $black; }
.bg-grey { background-color: $grey; }
.bg-blue { background-color: $blue; }
.bg-twitter_blue { background-color: $twitter_blue; }
.bg-purple { background-color: $purple; }
.bg-purple_light { background-color: $purple_light; }
.bg-purple_lighter { background-color: $purple_lighter; }